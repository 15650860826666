<template>
  <div class="wrapper">

    <div class="section section-grey">
      <div class="container">
        <div class="w-layout-grid grid-10">
          <ITPro_TV_Adam :config="presentation"/>
          <enroll :config="presentation"/>
        </div>
      </div>
      <div class="fixed-nav w-nav" data-animation="default" data-collapse="medium" data-duration="400" role="banner">
        <div class="container nav-container w-container">
          <a class="brand w-nav-brand" href="#">
            <img :src="require('../../public/images/Osidex-Stream-140-X-42.svg')" alt="" class="nav-logo">
          </a>
          <div class="menu-button w-nav-button">
            <img :src="require('../../public/images/menu-icon-white.svg')" alt="" loading="lazy">
          </div>
        </div>
        <div class="nav-gradient-container">
          <div class="gradient-line thin"></div>
        </div>
      </div>
    </div>

    <div class="preloader">
      <div class="preloader-content">
        <div class="preloader-line">
          <div class="preloader-gradient"></div>
        </div>
        <img alt="" loading="lazy" src="/images/Osidex-Stream-140-X-42.svg">
      </div>
    </div>
  </div>

  <!--  <div class="home">-->
  <!--    <div id="nav">-->
  <!--      <h1>Rapid Education Presents</h1>-->
  <!--    </div>-->

  <!--   <div class="row col-md-10 offset-md-1 col-sm-12">-->
  <!--     <div class="col-md-6 col-sm-12">-->
  <!--       <ITPro_TV_Adam :config="presentation"/>-->
  <!--     </div>-->

  <!--     <div class="col-md-6 col-sm-12">-->
  <!--       <enroll :config="presentation"/>-->
  <!--     </div>-->
  <!--   </div>-->

  <!--  </div>-->
</template>

<script>
// @ is an alias to /src
import ITPro_TV_Adam from '@/components/partners/ITPro_TV_Adam';
import Enroll from '@/components/Enroll';

export default {
  name: 'Presentation',
  components: {
    Enroll,
    ITPro_TV_Adam,
  },
  data: () => {
    return {
      presentation: {
        code: '',
        topic: 'loading',
        presenter: {
          name: 'loading',
        },
        company: {
          name: 'loading',
          logo: '',
        },
      },

    };
  },
  mounted() {
    this.presentation.code = this.$route.params.code;

    this.$http.get(`https://live-api.osidex.stream/streams/${this.presentation.code}/code`)
        .then(response => {

          if (response.data) {
            const data = response.data;

            if (data._id === '5fff3e7e011bb86298ab7ca4') {
              this.presentation = {
                code: data.code,
                topic: 'Launching Your Career in Cloud Computing',
                presenter: {
                  name: 'Prem Jadhwani',
                },
                company: {
                  name: 'Intellectual Point',
                  logo: 'https://cdn.discordapp.com/attachments/746219142000148480/811250752017137704/Digital_Natives_meetup_Logo.png',
                },
              };
            } else if (data._id === '5ffeffd17879ca406e8ebbc8') {
              this.presentation = {
                code: data.code,
                topic: 'How to Launch Your Career in IT with No Prior IT Experience',
                presenter: {
                  name: 'Prem Jadhwani',
                },
                company: {
                  name: 'Intellectual Point',
                  logo: 'https://cdn.discordapp.com/attachments/746219142000148480/811250752017137704/Digital_Natives_meetup_Logo.png',
                },
              };
            } else {
              this.presentation = {
                code: data.code,
                topic: 'Non-Described Presentation',
                presenter: {
                  name: 'Anonymous',
                },
                company: {
                  name: '',
                  logo: ''
                }
              };
            }

            this.presentation.id = data._id;
            document.title = this.presentation.topic;
          }

        })
        .catch(exception => {

          if (exception.response) {
            const response = exception.response;
            console.error(response);
          }

          console.error(exception);

        });

  },
};
</script>
