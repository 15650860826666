<template>
  <div id="w-node-fd4f95ad34bf-8ee527e0" class="content">
    <div class="card-item">
      <section id="contact-form" class="contact-form">
        <div class="w-container">
          <div class="contact-form-block w-form">
            <form id="wf-form-Contact-Form" data-name="Contact Form" name="wf-form-Contact-Form" style="color: white;">
              <div>
                <p class="paragraph-5">Your Full Name</p>
                <input id="Name" v-model="viewer.name" class="form-input w-input" data-name="Name" maxlength="256" name="Name" placeholder="Full Name" required="" type="text">
              </div>
              <div>
                <p class="paragraph-4">Your Email Address</p><input id="Email" v-model="viewer.email" class="form-input w-input" data-name="Email" maxlength="256" name="Email" placeholder="Email" required="" type="email">
              </div>
              <div class="tos-field">
                <label class="w-checkbox checkbox-field">
                  <input id="checkbox" v-model="viewer.agrees_tos" class="w-checkbox-input" data-name="Checkbox" name="checkbox" type="checkbox">
                  <span class="paragraph-2 w-form-label">
                    Do you agree to our <a href="https://osidex.stream/legal/terms-of-service">Terms and Service</a>?
                  </span>
                </label>
                <label class="w-checkbox">
                  <input id="checkbox-2" v-model="viewer.agrees_marketing" class="w-checkbox-input" data-name="Checkbox 2" name="checkbox-2" type="checkbox">
                  <span class="paragraph-2 w-form-label">
                    Do you agree to receive information related to this topic in the future?
                  </span>
                </label>
              </div>
              <div class="join-stream-button">
                <input type="button" :disabled="!prepared" class="button white-filled w-button" data-wait="Please wait..." value="Join Stream" @click="submitForm"></div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>

  <!--  <div>-->

  <!--    <h2 class="enroll-title">Enroll to Join {{ config.presenter.name }}</h2>-->

  <!--    <p>Please enter the following information and submit to join in this presentation.</p>-->

  <!--    <div class="col-md-10 offset-md-1 col-sm-12">-->

  <!--      <div class="form-group">-->
  <!--        <label for="viewer_email">Email address</label>-->
  <!--        <input id="viewer_email" v-model="viewer.email" class="form-control" minlength="5"-->
  <!--               placeholder="Enter your Email"-->
  <!--               type="email">-->
  <!--        <div class="invalid-feedback">Please enter a valid email address</div>-->
  <!--      </div>-->

  <!--      <div class="form-group">-->
  <!--        <label for="viewer_name">Full Name</label>-->
  <!--        <input id="viewer_name" v-model="viewer.name" class="form-control" minlength="4" placeholder="Enter your Name"-->
  <!--               type="text">-->
  <!--        <div class="invalid-feedback">Please enter your Full Name</div>-->
  <!--      </div>-->

  <!--      <div class="form-group">-->
  <!--        <label for="viewer_password">Password <small>(optional)</small></label>-->
  <!--        <input id="viewer_password" v-model="viewer.password" class="form-control" minlength="6"-->
  <!--               placeholder="Enter your Password"-->
  <!--               type="password">-->
  <!--        <div class="invalid-feedback">Please enter a password that is at least 6 characters long</div>-->
  <!--      </div>-->

  <!--      <div class="form-check d-inline-flex">-->
  <!--        <div class="col-md-6 col-sm-12">-->
  <!--          <input id="viewer_agrees_tos" v-model="viewer.agrees_tos" class="form-check-input" type="checkbox">-->
  <!--          <label class="form-check-label" for="viewer_agrees_tos">-->
  <!--            Do you agree to our <a href="https://osidex.stream/legal/terms-of-service">Terms and Service</a>?-->
  <!--          </label>-->
  <!--        </div>-->
  <!--        <div class="col-md-6 col-sm-12">-->
  <!--          <input id="viewer_agrees_marketing" v-model="viewer.agrees_marketing" class="form-check-input"-->
  <!--                 type="checkbox">-->
  <!--          <label class="form-check-label" for="viewer_agrees_marketing">-->
  <!--            Do you agree to receive information related to this topic in the future?-->
  <!--          </label>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--      <button class="tune-in btn btn-primary" type="submit" :disabled="!prepared" @click="submitForm">Tune Into-->
  <!--        Presentation-->
  <!--      </button>-->

  <!--    </div>-->

  <!--  </div>-->
</template>

<script>

import * as EmailValidator from 'email-validator';

export default {
  name: 'Enroll',
  props: {
    config: Object,
  },
  data: () => {
    return {
      viewer: {
        name: '',
        email: '',
        password: '',
        agrees_tos: false,
        agrees_marketing: true,
      },
    };
  },
  computed: {
    prepared: function() {
      return this.viewer.agrees_tos && this.viewer.name && this.isValidEmail;
    },
    isValidEmail: function() {
      return EmailValidator.validate(this.viewer.email);
    },
  },
  methods: {

    async submitForm() {

      if (this.viewer.password) {
        if (!await this.submitStreamRegistration(this.viewer.name, this.viewer.email, this.viewer.password)) return;
        delete this.viewer.password;
      }

      if (this.viewer.agrees_marketing) {
        this.submitMarketingRegistration();
      }

      localStorage.setItem('viewer', JSON.stringify(this.viewer));
      console.log(this.config);
      console.log(this.config.code);
      this.$router.push(`/${this.config.code}/watch?registered=true`);

    },
    async submitMarketingRegistration() {

      console.log(await this.$http.post(`https://live-api.osidex.stream/marketing/subscribe`, {
        name: this.viewer.name,
        email: this.viewer.email,
        stream_id: this.config.id
      }));

    },
    async submitStreamRegistration(name, email, password) {

      try {
        const response = await this.$http.post(`https://live-api.osidex.stream/auth/register`, {name, email, password});

        let data = response.data;

        console.log(data);

        if (data.token) {
          localStorage.setItem('ACCESS_TOKEN', data.token);
        }

        if (data.user) {
          localStorage.setItem('STREAM_USER', JSON.stringify(data.user));
        }

        return true;
      } catch (exception) {
        console.error(`Failed to submit OSIDEX Stream registration:`, exception);

        if (exception.response) {

          const response = exception.response;

          let data = response.data;
          console.log(data);

          if (data.errors) {

            let error = data.errors;

            if (error.msg === 'EMAIL_ALREADY_EXISTS') {
              this.$toastr.e('An account already exists using this email address', 'An error occurred');
            } else {
              console.error('Unknown Error: ' + error);
            }

          }

        }

        return false;
      }

    },

  },
};
</script>
