<template>
  <div id="w-node-fd4f95ad34b6-8ee527e0" class="content center">
    <div v-if="config.company.logo" class="image-holder">
      <img :alt="`${config.company.name} Logo`" :src="config.company.logo" class="company-logo image-101">
    </div>
    <h1 class="text-gradient-1">{{ config.presenter.name }} {{ config.company.name ? `of ${config.company.name}` : '' }}</h1>
    <div class="text-holder">
      <h6 class="white-40">{{ config.topic }}</h6>
    </div>
  </div>
  <!--  <div>-->
<!--      <img :alt="`${config.company.logo} Logo`" :src="config.company.logo" class="company-logo">-->
  <!--    <div class="hello">-->
  <!--      <h2>{{ config.presenter.name }} {{ config.company.name ? `of ${config.company.name}` : '' }}</h2>-->
  <!--      <p>-->
  <!--        <b>discussing </b> {{ config.topic }}-->
  <!--      </p>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>

export default {
  name: 'ITPro_TV_Adam',
  props: {
    config: Object,
  },
};
</script>
